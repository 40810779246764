@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  color: #740037;
  font-family: "Merriweather", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; 
}

svg {
  fill: "#740037"
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body, html , #root{
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 0;
  background-color: #fad8ca;
}

.merriweather-light {
  font-family: "Merriweather", serif;
  font-weight: 300;
  font-style: normal;
}

.merriweather-regular {
  font-family: "Merriweather", serif;
  font-weight: 400;
  font-style: normal;
}

.merriweather-bold {
  font-family: "Merriweather", serif;
  font-weight: 700;
  font-style: normal;
}

.merriweather-black {
  font-family: "Merriweather", serif;
  font-weight: 900;
  font-style: normal;
}

.merriweather-light-italic {
  font-family: "Merriweather", serif;
  font-weight: 300;
  font-style: italic;
}

.merriweather-regular-italic {
  font-family: "Merriweather", serif;
  font-weight: 400;
  font-style: italic;
}

.merriweather-bold-italic {
  font-family: "Merriweather", serif;
  font-weight: 700;
  font-style: italic;
}

.merriweather-black-italic {
  font-family: "Merriweather", serif;
  font-weight: 900;
  font-style: italic;
}

.tiles {
  display: grid;
}

table {
  width: 100%;
  background-color:rgb(255 247 237 / var(--tw-bg-opacity));
  border-radius: 6px;
}

thead {
  height: 40px;
  background-color: #740037;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  color: rgb(255 247 237 / var(--tw-bg-opacity));
}

tfoot {
  height: 10px;
}

th {
  text-align: left;
  padding: 5px 10px;
  
}

td {
  padding: 5px 10px;
}

tr {
  text-align: left;  
}

tr {
  padding-top: 6px;
}